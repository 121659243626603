const MainPage = () => import( "./components/MainPage")
const Gallery = () => import("./components/Gallery")
const Contact = () => import("./components/ContactPage")
const History = () => import("./components/historyPage")
export default [
    { path: '/', component: MainPage},
    { path: '/gallery', component: Gallery},
    { path: '/contact', component: Contact},
    { path: '/history', component: History}
]
